@font-face {
  font-family: 'TitilliumWeb';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Regular';
  src: url(/fonts/TitilliumWeb-Regular.ttf);
}

@font-face {
  font-family: 'TitilliumWeb-SemiBold';
  font-weight: 800;
  font-style: normal;
  font-display: block;
  font-named-instance: 'SemiBold';
  src: url(/fonts/TitilliumWeb-SemiBold.ttf);
}

@font-face {
  font-family: 'TitilliumWeb-Bold';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  font-named-instance: 'Bold';
  src: url(/fonts/TitilliumWeb-Bold.ttf);
}

@supports (font-variation-settings: normal) {
  * {
    font-family: 'TitilliumWeb';
    font-weight: 500;
  }
}
